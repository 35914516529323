import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { getUserContactsRequest, clearUserContacts } from '../../store/actions/contacts';
import EmptyList from '../../components/Shared/EmptyList';
import SearchBar from '../../components/Shared/SearchBar';
import { useAppDispatch, useAppSelector } from 'store';
import { useHistory } from 'react-router';
import { UserListItem } from 'components/UI/UserListItem';
import { Virtuoso } from 'react-virtuoso';
import Loader from 'components/UI/Loader';

const Contacts: FC = () => {
  const dispatch = useAppDispatch();
  const { contacts, contactsIsLoading, contactsCount } = useAppSelector((state) => state.contacts);
  const styles = useStyles({});
  const history = useHistory();
  const userId = localStorage.getItem('userId');
  const [state, setState] = useState({
    query: '',
    pageNumber: 1,
  });

  useEffect(() => {
    if (history.action === 'PUSH') {
      dispatch(clearUserContacts());
    }
  }, []);

  const getMoreUsersHandler = () => {
    const { pageNumber, query } = state;
    if (contactsIsLoading) return;
    if (contacts?.length === contactsCount) return;

    if (userId) {
      dispatch(getUserContactsRequest(false, userId, pageNumber, 20, query));
    }

    setState((c) => ({
      ...c,
      pageNumber: c.pageNumber + 1,
    }));
  };

  useEffect(() => {
    if (!contacts.length) {
      dispatch(getUserContactsRequest(false, userId, 1, 20));
    }
  }, [contacts.length]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.trim().length === 0) {
      clearSearchHandler();
    } else {
      setState({ pageNumber: 2, query: value });

      if (userId) {
        dispatch(getUserContactsRequest(true, userId, 1, 20, value));
      }
    }
  };

  const clearSearchHandler = () => {
    const userId = localStorage.getItem('userId');
    dispatch(clearUserContacts());
    setState({
      query: '',
      pageNumber: 2,
    });

    if (userId) {
      dispatch(getUserContactsRequest(true, userId, 1, 20, ''));
    }
  };

  const toProfileDetailsHandler = (_id: string | number) => {
    history.push(`/profile-details/${_id}`, { userId: _id });
  };

  const Footer = () => <Loader />;

  return (
    <div className={styles.container}>
      <div className={styles.inputWrapper}>
        <SearchBar query={state.query} onChange={onChangeHandler} clearSearch={clearSearchHandler} />
      </div>
      {contacts?.length > 0 ? (
        <Virtuoso
          style={{ height: '74vh' }}
          data={contacts}
          totalCount={contactsCount}
          endReached={getMoreUsersHandler}
          itemContent={(index, { photo, lastname, name, corp, wohnort, _id }) => (
            <UserListItem
              name={`${name} ${lastname}`}
              image={photo}
              description={wohnort}
              corpName={corp[0].corpsname}
              key={index}
              onClick={() => toProfileDetailsHandler(_id)}
            />
          )}
          components={{ Footer }}
        />
      ) : (
        !contactsIsLoading && <EmptyList />
      )}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    width: '100%',
    padding: '10px 0 20px 0',
    overflow: 'auto',
  },
  title: {
    textAlign: 'center',
  },
  inputWrapper: {
    padding: '10px 20px 0',
  },
});

export default Contacts;
