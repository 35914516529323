import { Box, ButtonBase } from '@material-ui/core';
import { AttachmentImage } from 'components/Shared/AttachmentImage';
import { fileType } from 'store/reducers/attachments';
import { ReactComponent as CloseIcon } from '../../../assets/close.svg';
import Video from 'react-player';
import { getConfig } from 'config/config';
import { ProgressLoader } from 'components/UI/ProgressLoader';
import { useAppDispatch, useAppSelector } from 'store';
import { mediaActions } from 'store/reducers/mediaSlice';
import moment from 'moment';
import { TSurveyModal } from 'components/Shared/SurveyModal/SurveyModal';
import { IonIcon } from 'components/UI/IonIcon';
import { useLanguage } from 'languages/languageContext';
import { PDFLoader } from './PDFLoader';

import SortableList, { SortableItem } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';

type columnProps = {
  viewType: 'column';
  survey?: TSurveyModal & {
    setSignalToOpen: () => void;
    deleteSurvey: () => void;
  };
};
type rowProps = {
  viewType: 'row';
};

type basicProps = {
  currentAttachments: { documents: fileType[]; images: fileType[]; videos: fileType[] };
  // delete me
  deleteAttachment?: (type: 'documents' | 'images' | 'videos', idx: number) => void;
};

type viewProps = columnProps | rowProps;

export const RenderStateAttachments = (props: viewProps & basicProps) => {
  const { currentAttachments, viewType = 'row' } = props;

  const deleteAttachment = (type: 'documents' | 'images' | 'videos', id: number) => {
    dispatch(mediaActions.deleteAttachment({ type, id }));
  };

  const { surveys } = useLanguage();

  const dispatch = useAppDispatch();

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    dispatch(
      mediaActions.setAttachments({
        type: 'images',
        order: arrayMoveImmutable(currentAttachments?.images, oldIndex, newIndex),
      }),
    );
  };

  const columnDocument = (file: any, i: number) => (
    <Box
      style={{
        ...styles.icon,
        marginBottom: 30,
        position: 'relative',
      }}
      key={'documents' + i}
    >
      <DeleteButton type="documents" idx={i} />
      <AttachmentImage file={file} type={file?.mimetype?.includes('pdf') ? 'document' : 'file'} viewType={viewType} />
      <ProgressLoader loading={!!file.progress} progress={file.progress} />
      <p style={{ ...styles.name, wordWrap: 'break-word', textAlign: 'center', fontSize: 10 }}>{file.fileName}</p>
    </Box>
  );

  const ColumnImage = ({ file, i }: { file: any; i: number }) => (
    <Box
      style={{
        ...styles.icon,
        marginBottom: 30,
        position: 'relative',
      }}
      key={'images' + i}
    >
      <Box style={{ ...styles.deleteButtonWrapper, position: 'absolute' }}>
        <ButtonBase onClick={() => deleteAttachment('images', i)}>
          <Box style={{ ...styles.delWrapper, position: 'relative' }}>
            <Box style={{ ...styles.delImageWrapper }}>
              <CloseIcon />
            </Box>
          </Box>
        </ButtonBase>
      </Box>
      <AttachmentImage file={file} type="image" viewType={viewType} />
      <ProgressLoader loading={!!file.progress} progress={file.progress} />
    </Box>
  );

  const columnVideo = (file: any, i: number) => (
    <div className="relative select-none cursor-grab opacity-100 z-10" key={'videos' + i}>
      <DeleteButton idx={i} type="videos" />
      <Video
        width={140}
        height={80}
        url={file.renderUrl || file.url}
        style={{ ...styles.image, marginRight: 15, position: 'relative' }}
        controls={false}
        paused={true}
        onDuration={(duration) => {
          dispatch(mediaActions.setUploadingProgress({ type: 'videos', idx: i, file: { duration } }));
        }}
      />
      <span className="text-xs text-white bg-primary-btn rounded-md p-[2px] absolute bottom-[15px] right-[15px]">
        {moment(file?.duration * 1000 || 0).format('mm:ss')}
      </span>
      <ProgressLoader loading={!!file.progress} progress={file.progress} />
    </div>
  );

  const rowDocument = (file: any, i: number) => (
    <Box style={{ ...styles.iconRow, flexDirection: 'row', display: 'flex' }} key={'documents' + i}>
      <Box style={{ flexDirection: 'row', display: 'flex' }}>
        <AttachmentImage viewType={viewType} type={file?.mimetype?.includes('pdf') ? 'document' : 'file'} file={file} />
        <span style={{ ...styles.nameRow, wordWrap: 'break-word', maxWidth: 350 }}>{file.fileName}</span>
      </Box>
      <ButtonBase style={{ alignSelf: 'center' }} onClick={() => deleteAttachment('documents', i)}>
        <Box style={styles.delImageWrapper}>
          <CloseIcon />
        </Box>
      </ButtonBase>
    </Box>
  );

  const rowImage = (file: any, i: number) => (
    <Box style={{ ...styles.iconRow, flexDirection: 'row', display: 'flex' }} key={'images' + i}>
      <Box style={{ flexDirection: 'row', display: 'flex' }}>
        <AttachmentImage viewType={viewType} type="image" file={file} />
        <span style={styles.nameRow}>{file.fileName}</span>
      </Box>
      <ButtonBase style={{ alignSelf: 'center' }} onClick={() => deleteAttachment('images', i)}>
        <Box style={styles.delImageWrapper}>
          <CloseIcon />
        </Box>
      </ButtonBase>
    </Box>
  );

  const rowVideo = (file: any, i: number) => (
    <Box style={{ ...styles.iconRow, flexDirection: 'row', display: 'flex' }} key={'videos' + i}>
      <Box style={{ flexDirection: 'row', display: 'flex' }}>
        <Video
          width={40}
          height={40}
          url={file.renderUrl || file.url}
          style={{ ...styles.image, position: 'relative' }}
          controls={false}
          paused={true}
          onDuration={(duration) => {
            dispatch(mediaActions.setUploadingProgress({ type: 'videos', idx: i, file: { duration } }));
          }}
        />
        <Box position={'relative'} left={-19} top={-8}>
          <ProgressLoader loading={!!file.progress} progress={file.progress} />
        </Box>
        <span
          style={{
            height: 14,
            fontSize: 11,
            marginTop: 8,
            color: '#fff',
            position: 'relative',
            bottom: -14,
            right: 20,
            backgroundColor: theme.BUTTON_ICON,
            padding: 2,
            borderRadius: 5,
            fontWeight: 'bolder',
          }}
        >
          {moment(file?.duration * 1000 || 0).format('mm:ss')}
        </span>
        <span style={styles.nameRow}>{file.fileName}</span>
      </Box>
      <ButtonBase onClick={() => deleteAttachment('videos', i)}>
        <Box style={{ position: 'relative' }}>
          <Box style={styles.delImageWrapper}>
            <CloseIcon />
          </Box>
        </Box>
      </ButtonBase>
    </Box>
  );

  const Survey = () =>
    props.viewType === 'column' &&
    !!props?.survey?.question && (
      <Box
        style={{
          ...styles.icon,
          marginBottom: 30,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: 100,
        }}
        key={'survey'}
      >
        <Box style={{ ...styles.deleteButtonWrapper, position: 'absolute', left: 60 }}>
          <ButtonBase onClick={props.survey.deleteSurvey}>
            <Box style={{ ...styles.delWrapper, position: 'relative' }}>
              <Box style={{ ...styles.delImageWrapper }}>
                <CloseIcon />
              </Box>
            </Box>
          </ButtonBase>
        </Box>
        <ButtonBase
          onClick={props.survey.setSignalToOpen}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IonIcon name="podium-outline" size={40} color={theme.ACTIVE_INPUT} style={{ rotate: '90deg' }} />
          <span style={{ color: theme.ACTIVE_INPUT, fontWeight: 'bolder' }}>{surveys.createModalTitle}</span>
        </ButtonBase>
      </Box>
    );

  const DeleteButton = ({ type, idx }: { type: 'images' | 'videos' | 'documents'; idx: number }) => (
    <button
      onClick={() => deleteAttachment(type, idx)}
      className="absolute z-20 w-6 h-6 flex items-center justify-center bg-gray-500/50 rounded-md top-[0px] right-[15px] hover:bg-gray-400 hover:scale-105 duration-75 backdrop-blur-sm"
    >
      <IonIcon name="trash" color={theme.ERROR_PRIMARY} />
    </button>
  );

  return (
    <Box
      style={{
        ...styles.imagesWrapper,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '100%',
      }}
    >
      <Survey />
      {currentAttachments?.documents?.map(viewType === 'column' ? columnDocument : rowDocument)}
      {viewType === 'column' ? (
        <SortableList onSortEnd={onSortEnd} className="grid grid-cols-2 md:grid-cols-3 gap-3 md:gap-6 mb-4">
          {currentAttachments?.images?.map((item, idx) => (
            <SortableItem key={item.renderUrl}>
              <div className="relative select-none cursor-grab opacity-100 z-10">
                <DeleteButton type={'images'} idx={idx} />
                <AttachmentImage file={item} type="image" viewType={viewType} />
                <ProgressLoader loading={!!item.progress} progress={item.progress} />
                <div className="absolute bottom-[15px] right-[15px] w-4 h-4 rounded-md text-xs bg-slate-500/50 items-center justify-center flex backdrop-blur-sm">
                  {idx + 1}
                </div>
              </div>
            </SortableItem>
          ))}
        </SortableList>
      ) : (
        currentAttachments?.images?.map(rowImage)
      )}
      {currentAttachments?.videos?.map(viewType === 'column' ? columnVideo : rowVideo)}
    </Box>
  );
};

const { theme } = getConfig();
const styles = {
  imagesWrapper: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginHorizontal: 2,
  },

  deleteButtonWrapper: {
    zIndex: 1,
    top: -15,
    right: 0,
  },
  delWrapper: {
    padding: 5,
    zIndex: 1,
  },
  delImageWrapper: {
    width: 25,
    height: 25,
    borderRadius: 12.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.BACKGROUND_SECONDARY,
  },
  name: {
    marginTop: 10,
    color: theme.TEXT_PRIMARY,
    maxWidth: 140,
  },
  nameRow: {
    color: theme.TEXT_PRIMARY,
    fontSize: 12,
    alignSelf: 'center',
    marginLeft: 5,
  },
  icon: {
    alignItems: 'center',
    marginHorizontal: '1.6%',
    height: 80,
    marginRight: 15,
  },
  iconRow: {
    width: '100%',
    alignSelf: 'center',
    alignItems: 'flex-start',
    marginHorizontal: '1.6%',
    height: 40,
    marginBottom: 15,
    justifyContent: 'space-between',
  },
  image: {
    backgroundColor: '#ccc',
    width: 140,
    height: 80,
    marginBottom: 15,
    zIndex: 0,
    borderRadius: 5,
  },
};
