import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import { FormInput } from '../../components/UI/FormInput';
import { useLanguage } from '../../languages/languageContext';
import Button from '../../components/UI/Button';
import moment from 'moment';

import SearchableModal from '../../components/Shared/SearchableModal';
import { MultipleSearchableInput } from '../../components/Shared/MultipleSearchableInput';
import { countries } from '../../utilities/countries';
import { getConfig } from '../../config/config';
import { useAppDispatch, useAppSelector } from '../../store';
import { UploadMedia } from '../../components/Shared/UploadMedia';
import { mediaActions } from '../../store/reducers/mediaSlice';
import { editProfileValidations } from '../../utilities/schema';
import { shownConfirmation, updateProfileBasic } from '../../api/users';

import { TunverifiedField, UserType } from '../../store/types/usersTypes';
import { NewDatePicker } from 'components/shadcn/datepicker';
import { Checkbox } from 'components/shadcn/checkbox';
import { Label } from 'components/shadcn/label';
import { RenderStateAttachments } from 'scenes/DialogPanel/components/RenderStateAttachments';
import LanguageSwitch from 'components/UI/LanguageSwitch';
import { hideResponse, showResponse } from 'store/actions/response';
import { getOwnerProfileRequest } from 'store/actions/users';
import { InputButton } from 'components/UI/InputButton';
import ChangePasswordModal from 'scenes/ProfileBasis/components/ChangePasswordModal';
import { filterFields, removeUnchangedFields } from 'utilities/removeUnchangedFields';
import { PhoneInput } from 'components/shadcn/phoneInput';

const { config, theme } = getConfig();

export const EditProfile = () => {
  const dispatch = useAppDispatch();
  const { profileDetails, searchModal, btn, invalidInputs, profile, responseHandler, signUpManual } = useLanguage();

  const { allow_attachments, allow_bio, allow_link, show_phone } = useAppSelector((state) => state.common);

  const { loadingCounter, loading, ...media } = useAppSelector((state) => state.media);

  const { user } = useAppSelector((state) => state.users);

  const [countriesState, setCountriesState] = useState({
    showCountriesModal: false,
    countriesQuery: '',
  });

  const [showChangePassword, setShowChangePassword] = useState(false);

  useEffect(() => {
    initMedia();
    const filtered = filterFields(initialValues, user);
    formik.setValues(filtered);
    return () => {
      dispatch(mediaActions.deleteAllAttachments());
    };
  }, [user]);

  const initMedia = () => {
    const images = user.attachments?.image?.length ? [{ url: user.attachments?.image }] : [];
    const videos = user.attachments?.video?.length ? [{ url: user.attachments?.video }] : [];
    const documents = user.attachments?.pdf?.length ? [{ url: user.attachments?.pdf }] : [];
    const payload = {
      images,
      videos,
      documents,
    };
    // @ts-ignore
    dispatch(mediaActions.setEditingAttachments(payload));
  };

  const getUnverifiedValue = (field: keyof UserType) =>
    user.unverifiedFields?.find((el: TunverifiedField) => el.field === field);

  const initialValues: Partial<UserType> = {
    title: user.title || '',
    name: user.name || '',
    lastname: user.lastname || '',
    email: user.email || '',
    postcode: user.postcode || '',
    country: user.country || '',
    wohnort: user.wohnort || '',
    birthday: user.birthday || '',
    mobilfunknummer: user.mobilfunknummer || '',
    street: user.street || '',
    partner: user.partner || '',
    telephoneLandline: user.telephoneLandline || '',
    showBirthday: user.showBirthday || false,
    leibbursch: user.leibbursch || '',
    leibfuechse: user.leibfuechse || '',
    bio: user.bio || '',
    attachments: {
      link: user.attachments?.link || '',
      image: user.attachments?.image || '',
      video: user.attachments?.video || '',
      pdf: user.attachments?.pdf || '',
    },
  };

  const { values, errors, handleChange, handleBlur, touched, setFieldValue, handleSubmit, isValid, ...formik } =
    useFormik({
      initialValues,
      validationSchema: editProfileValidations({ invalidInputs }),
      onSubmit: async (values) => {
        const onlyNewValues = removeUnchangedFields(initialValues, values);

        const res = await updateProfileBasic(onlyNewValues);
        // @ts-ignore
        formik.setValues(res);

        if (res?.unverifiedFields.length && res?.unverifiedFields?.some((el) => !el.shown)) {
          const message = (
            <div key="profileUpdated" className="flex flex-col items-center break-normal">
              <span className="font-semibold">Profil aktualisiert.</span>
              <br />
              <span className="text-center">
                Bitte validiere die folgenden Datenfelder via E-Mail. Nur dann werden sie an die zentrale
                Mitgliederverwaltung übermittelt.
              </span>
              <br />
              {res.unverifiedFields.map((el) =>
                el.shown ? null : (
                  <span>
                    <strong>{profileDetails?.[el.field] || el.field}</strong>: Neuer Wert = "{el.newValue}"
                  </span>
                ),
              )}
              <br />
              <br />
              <span className="text-center">Oder sollen wir die Validierungs-Mail erneut senden?</span>
            </div>
          );

          const newObj = {};
          res.unverifiedFields.forEach(async (field) => {
            newObj[field.field] = field.newValue;
            await shownConfirmation(field.field);
          });

          dispatch(
            showResponse({
              title: 'Validierung ausstehend',
              message,
              messageAlignLeft: true,
              cancelBtnLabel: 'Erneut senden',
              onClick: async () => {
                await updateProfileBasic(newObj);
                dispatch(hideResponse());
                dispatch(
                  showResponse({
                    message:
                      'Die Validierungs-Mail wurde erneut gesendet. Bitte warte einige Minuten und überprüfe auch deinen Spam-Ordner.',
                  }),
                );
              },
              options: [<Button label={btn.ok} onClick={() => dispatch(hideResponse())} />],
            }),
          );
        } else {
          dispatch(
            showResponse({
              message: responseHandler.profileUpdated,
            }),
          );
        }

        dispatch(getOwnerProfileRequest(user._id));
      },
    });

  return (
    <React.Fragment>
      <div style={{ flex: 1, width: '100%' }}>
        <FormInput
          name="title"
          placeholder={profileDetails.title}
          onBlur={handleBlur('title')}
          onChange={handleChange('title')}
          value={values.title}
          error={errors.title as string}
          touched={touched.title as boolean}
          unverifiedFields={getUnverifiedValue('title')}
        />

        <FormInput
          name="name"
          type="name"
          placeholder={profileDetails.firstName}
          onBlur={handleBlur('name')}
          onChange={handleChange('name')}
          value={values.name}
          error={errors.name as string}
          touched={touched.name as boolean}
          unverifiedFields={getUnverifiedValue('name')}
        />
        <FormInput
          name="lastname"
          type="name"
          placeholder={profileDetails.lastName}
          onBlur={handleBlur('lastname')}
          onChange={handleChange('lastname')}
          value={values.lastname}
          error={errors.lastname as string}
          touched={touched.lastname as boolean}
          unverifiedFields={getUnverifiedValue('lastname')}
        />

        <FormInput
          name="email"
          type="email"
          placeholder={profileDetails.email}
          onBlur={handleBlur('email')}
          onChange={handleChange('email')}
          value={values.email}
          error={errors.email as string}
          touched={touched.email as boolean}
          unverifiedFields={getUnverifiedValue('email')}
        />
        {(show_phone || (config.SHOW_MOBILE && config.APP_NAME === 'rhenania')) && (
          <PhoneInput
            name="mobilfunknummer"
            type="tel"
            placeholder={signUpManual.mobilePlaceholder}
            onBlur={handleBlur}
            value={values.mobilfunknummer}
            defaultCountry="DE"
            error={errors.mobilfunknummer}
            touched={touched.mobilfunknummer}
            onChange={(phoneNumber) => setFieldValue('mobilfunknummer', phoneNumber)}
            international
          />
        )}
        <NewDatePicker
          name={'birthday'}
          placeholder={profileDetails.birthday}
          date={values.birthday ? moment(values.birthday) : undefined}
          error={touched.birthday && (errors.birthday as string)}
          setDate={(e) => {
            setFieldValue('birthday', moment(e).set('hours', 12).toString());
          }}
        />

        <div className="flex flex-row gap-2 mb-3 ml-2">
          <Checkbox
            checked={values.showBirthday}
            onCheckedChange={() => setFieldValue('showBirthday', !values.showBirthday)}
          />
          <Label>{profileDetails.showBirthday}</Label>
        </div>

        {(config.WITH_ADRESS || config.APP_NAME === 'soroptimist' || config.APP_NAME === 'gdl') && (
          <FormInput
            name="street"
            placeholder={profileDetails.street}
            onBlur={handleBlur('street')}
            onChange={handleChange('street')}
            value={values.street}
            error={errors.street as string}
            touched={touched.street as boolean}
            unverifiedFields={getUnverifiedValue('street')}
          />
        )}

        <FormInput
          name="postcode"
          placeholder={profileDetails.postcode}
          onBlur={handleBlur('postcode')}
          onChange={handleChange('postcode')}
          value={values.postcode}
          error={errors.postcode as string}
          touched={touched.postcode as boolean}
          unverifiedFields={getUnverifiedValue('postcode')}
        />

        <FormInput
          name="wohnort"
          placeholder={profileDetails.wohnort}
          onBlur={handleBlur('wohnort')}
          onChange={handleChange('wohnort')}
          value={values.wohnort}
          error={errors.wohnort as string}
          touched={touched.wohnort as boolean}
          unverifiedFields={getUnverifiedValue('wohnort')}
        />
        <InputButton
          onClick={() => {
            formik.setTouched({ country: true });
            setCountriesState((c) => ({ ...c, showCountriesModal: true }));
          }}
          placeholder={profileDetails.country}
          value={values.country}
          label={profileDetails.country}
          touched={touched.country as boolean}
          error={errors.country as string}
          unverifiedFields={getUnverifiedValue('country')}
        />
        {config.WITH_PARTNER && (
          <FormInput
            name="partner"
            placeholder={profileDetails.partner}
            onBlur={handleBlur('partner')}
            onChange={handleChange('partner')}
            value={values.partner}
            error={errors.partner as string}
            touched={touched.partner as boolean}
            unverifiedFields={getUnverifiedValue('partner')}
          />
        )}

        {config.WITH_MENTOR && (
          <FormInput
            name="leibbursch"
            placeholder={profileDetails.leibbursch}
            onBlur={handleBlur('leibbursch')}
            onChange={handleChange('leibbursch')}
            value={values.leibbursch}
            error={errors.leibbursch as string}
            touched={touched.leibbursch as boolean}
            unverifiedFields={getUnverifiedValue('leibbursch')}
          />
        )}

        {config.WITH_MENTOR && (
          <FormInput
            name="leibfuechse"
            placeholder={profileDetails.leibfuechse}
            onBlur={handleBlur('leibfuechse')}
            onChange={handleChange('leibfuechse')}
            value={values.leibfuechse}
            error={errors.leibfuechse as string}
            touched={touched.leibfuechse as boolean}
            unverifiedFields={getUnverifiedValue('leibfuechse')}
          />
        )}

        {allow_bio && (
          <FormInput
            name="bio"
            placeholder={profileDetails.bio}
            onBlur={handleBlur('bio')}
            onChange={handleChange('bio')}
            value={values.bio}
            error={errors.bio as string}
            touched={touched.bio as boolean}
            unverifiedFields={getUnverifiedValue('bio')}
          />
        )}

        {allow_link && (
          <FormInput
            name="link"
            placeholder={profileDetails.link}
            onBlur={handleBlur('attachments.link')}
            onChange={handleChange('attachments.link')}
            value={values.attachments?.link}
            error={errors.attachments?.link as string}
            touched={touched.attachments?.link as boolean}
            // @ts-ignore
            unverifiedFields={getUnverifiedValue('attachments.link')}
          />
        )}
        {allow_attachments && (
          <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 15 }}>
              <UploadMedia
                addVideoDisabled={!!media.videos?.length}
                addPictureDisabled={!!media.images?.length}
                addPDFDisabled={!!media.documents?.length}
                multiple={false}
              />
              <span>{profileDetails.addDocuments}</span>
            </div>
            <RenderStateAttachments currentAttachments={media} deleteAttachment={() => {}} viewType="column" />
          </div>
        )}
        <LanguageSwitch />

        <Button
          onClick={() => setShowChangePassword(true)}
          background={theme.BUTTON_PRIMARY}
          label={profile.changePasswordLabel}
        />

        <Button
          onClick={handleSubmit}
          background={isValid ? theme.BUTTON_PRIMARY : theme.ICON_BORDER}
          label={btn.saveBtn}
        />
      </div>
      {showChangePassword && (
        <ChangePasswordModal
          showModal={showChangePassword}
          onClose={() => setShowChangePassword(false)}
          modalTitle={profile.changePasswordLabel}
          message=""
        />
      )}
      <SearchableModal
        onClick={() => setCountriesState((c) => ({ ...c, showCountriesModal: false }))}
        setValueHandler={() => setCountriesState((c) => ({ ...c, showCountriesModal: false }))}
        modalTitle={searchModal.landTitle}
        modalOpen={countriesState.showCountriesModal}
      >
        <MultipleSearchableInput
          data={countries || []}
          onSelect={(value) => setFieldValue('country', value)}
          onChange={(e) => setCountriesState((c) => ({ ...c, countriesQuery: e.target.value }))}
          deleteItemHandler={() => setFieldValue('country', [])}
          value={countriesState.countriesQuery}
          values={[values.country]}
          inputName="currentCountry"
        />
      </SearchableModal>
    </React.Fragment>
  );
};
