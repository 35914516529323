import { joinEventPart, leaveEventPart, unvoteEventPart, voteEventPart } from 'api/events';
import { IonIcon } from 'components/UI/IonIcon';
import { useCustomHistory } from 'hooks/useCustomHistory';
import { useLanguage } from 'languages/languageContext';
import moment from 'moment';
import { FC, Fragment } from 'react';
import { TEventPart } from 'scenes/CreateEvent/components/EventParts/EventPart';
import { useAppDispatch, useAppSelector } from 'store';
import { getEventByIdSuccess } from 'store/actions/events';
import { showResponse } from 'store/actions/response';
import { cn } from 'utilities/utils';
import FreeTextInput from './FreeTextInput';
import { ReactComponent as ClockIcon } from '../../../assets/clock.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/calendar.svg';

const EventPart: FC<
  TEventPart & { _id: string; members: { id: string; _id: string; acceptedAt: string }[]; isCreator: boolean }
> = (props) => {
  const { title, start, end, location, description, _id, members, questions, price, maxMembers, isCreator } = props;
  const {
    eventLabels,
    btn,
    privacy: { restrictions },
  } = useLanguage();
  const dispatch = useAppDispatch();
  const { push } = useCustomHistory();

  const { user } = useAppSelector((state) => state.users);
  const userId = user._id;
  const event = useAppSelector((state) => state.events.currentEvent);

  const isJoined = members.some((member) => member.id === userId);

  return (
    <div className={cn('flex flex-row my-2 mx-4 rounded-sm')}>
      <div className={cn('rounded-l-sm w-2 bg-slate-200 duration-200', isJoined && 'bg-primary-btn/80')} />
      <div className="w-full pl-2 flex flex-col">
        <div className="flex flex-row">
          <div className="text-secondary-txt w-[125px] mb-1">{eventLabels.titleLabel}:</div>
          <span className="text-primary-txt font-semibold">{title}</span>
        </div>
        <div className="flex flex-row">
          <div className="text-secondary-txt w-[125px] mb-1">{eventLabels.descLabel}:</div>
          <span className="text-primary-txt font-medium">{description}</span>
        </div>
        <div className="flex flex-row">
          <div className="text-secondary-txt w-[125px] mb-1">{eventLabels.locationLabel}:</div>
          <span className="text-primary-txt font-medium">{location}</span>
        </div>
        <div className="flex flex-row items-center">
          <div className="text-secondary-txt w-[125px] mb-1">{eventLabels.startLabel}:</div>
          <CalendarIcon />
          <span className="text-primary-txt font-medium ml-1 mr-5">{moment(start).format('DD.MM.YYYY')}</span>
          <ClockIcon />
          <span className="text-primary-txt font-medium ml-1">{moment(start).format('HH:mm')}</span>
        </div>
        <div className="flex flex-row items-center">
          <div className="text-secondary-txt w-[125px] mb-1">{eventLabels.eventEndLabel}:</div>
          <CalendarIcon />
          <span className="text-primary-txt font-medium ml-1 mr-5">{moment(end).format('DD.MM.YYYY')}</span>
          <ClockIcon />
          <span className="text-primary-txt font-medium ml-1">{moment(end).format('HH:mm')}</span>
        </div>
        {!!maxMembers && (
          <div className="flex flex-row">
            <div className="text-secondary-txt w-[125px] mb-1">{eventLabels.participantLabel}:</div>{' '}
            <span className="text-primary-txt font-medium">
              {members.length} / {maxMembers}
            </span>
          </div>
        )}
        {!!price && (
          <div className="flex flex-row">
            <div className="text-secondary-txt w-[125px] mb-1">Preis:</div>
            <span className="text-primary-txt font-medium">{price} EUR</span>
          </div>
        )}
        {questions.map((question) => {
          // const votersNumber = question.options.reduce((acc, opt) => acc + opt.voters?.length, 0);
          // used onlu when type === 'single'
          const votedAny =
            question.type === 'single' && question.options.find((el) => el.voters.some((item) => item.user === userId));

          // const freeAnswer = freeText.find((opt) => opt.qId === question._id);
          const freeOpt =
            question.type === 'freeText'
              ? {
                  questionId: question._id,
                  answerId: question?.options?.[0]?._id,
                  value: question?.options?.[0]?.title,
                }
              : {};

          return (
            <div className="my-2" key={question._id}>
              <span className="font-semibold block">{question.text}</span>
              <span className="text-txt-secondary text-sm">{eventLabels.parts[question.type]}</span>
              <div className="mt-2">
                {question.type === 'freeText' && (
                  <FreeTextInput
                    key={freeOpt.questionId}
                    partId={_id}
                    answerId={freeOpt.answerId}
                    event={event}
                    value={freeOpt.value}
                  />
                )}
                {question.options.map((option) => {
                  if (question.type === 'freeText') return <></>;
                  const checked = option.voters.some((el) => el.user === userId);
                  return (
                    <Fragment key={option._id}>
                      <button
                        onClick={async () => {
                          let res;
                          if (votedAny?._id && !checked) {
                            await unvoteEventPart(event._id, votedAny._id);
                            res = await voteEventPart(event._id, option._id);
                          } else if (checked) {
                            res = await unvoteEventPart(event._id, option._id);
                          } else if (!checked) {
                            res = await voteEventPart(event._id, option._id);
                          }
                          delete res?.organizers;
                          dispatch(getEventByIdSuccess({ ...event, ...res }));
                        }}
                        className="mb-2 w-full text-left hover:opacity-50 flex flex-row flex-1"
                      >
                        <div
                          className={cn(
                            'w-[24px] h-[24px] bg-slate-200 rounded-full mr-2 self-end border border-slate-300 items-center justify-center flex',
                            checked && 'bg-primary-btn',
                          )}
                        >
                          {checked && <IonIcon name="checkmark" color="white" size={20} />}
                        </div>

                          <span>{option.title}</span>
                      </button>
                    </Fragment>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div>
          <button
            className={cn(
              'w-fit px-3 py-1 rounded-md bg-primary-btn text-white hover:opacity-50 mt-4 duration-200',
              isJoined && 'bg-red-500',
            )}
            onClick={async () => {
              let res;
              if (!isJoined) res = await joinEventPart(_id);
              else res = await leaveEventPart(_id);
              delete res?.organizers;
              dispatch(getEventByIdSuccess({ ...event, ...res }));
            }}
          >
            {isJoined ? btn.cancelEventBtn : btn.acceptBtn}
          </button>
          {isCreator && (
            <button
              className={cn('w-fit px-3 py-1 rounded-md text-secondary-btn  mt-4 ml-4 duration-200 hover:bg-slate-100')}
              onClick={() => {
                if (user?.isGuest) {
                  dispatch(
                    showResponse({
                      message: restrictions.participantsList,
                    }),
                  );
                  return;
                }
                push('/parts-participants/' + event._id, { event, preunfolded: _id });
              }}
            >
              {eventLabels.parts.showMembers}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventPart;
