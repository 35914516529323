import axios from "axios";


export const downloadFile = async (fileUrl: string, name: string, withCredentials: boolean = false) => {
  try {
    const response = await axios.get(fileUrl, {withCredentials })
    const blob =  new Blob(response.data);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error occurred while downloading the file:', error);
  }
};
