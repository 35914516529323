import React, { FC, useEffect } from 'react';
import { ButtonBase, makeStyles } from '@material-ui/core';
import DrawerProfile from './components/DrawerProfile';
import DrawerNav from './components/DrawerNav';
import DrawerFooter from './components/DrawerFooter';
import { ReactComponent as CloseIcon } from './assets/close_button.svg';
import { useAppDispatch, useAppSelector } from 'store';
import { getConfig } from 'config/config';
import { getOwnerProfileRequest } from 'store/actions/users';
import { getCorpsNamesRequest } from 'store/actions/corps';
import { closeMenu } from 'store/actions/layout';
import { getKreiseToPost } from 'store/reducers/news';
import { getChatUsersRequest } from 'store/actions/chat';

const { theme } = getConfig();

const useStyles = makeStyles({
  container: {
    marginTop: 55,
    borderRadius: 10,
    border: '1px solid #ccc',
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 2,
  },
  closeButtonWrapper: {
    display: 'none',
  },
  closeButton: {
    padding: '10px 20px 0',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
  },
  menuButton: {
    display: 'none',
  },

  '@media(max-width: 1024px)': {
    container: {
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  '@media(max-width: 650px)': {
    container: {
      marginTop: 0,
      height: '100vh',
    },
    menuButton: {
      display: 'block',
    },
    closeButtonWrapper: {
      display: 'block',
    },
  },
});

const Drawer: FC<{ handleLogOut?: () => void }> = ({ handleLogOut }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles({});

  const { chatUsers } = useAppSelector((state) => state.chat);
  const { corpsNames } = useAppSelector((state) => state.corps);
  const { user, userIsLoading } = useAppSelector((state) => state.users);

  useEffect(() => {
    getUserHandler();
    getCorpsNamesHandler();
    dispatch(getKreiseToPost());
    if (!chatUsers) {
      setTimeout(() => {
        dispatch(getChatUsersRequest(true));
      }, 1000);
    }
  }, []);

  const getCorpsNamesHandler = () => {
    if (!corpsNames) {
      dispatch(getCorpsNamesRequest());
    }
  };

  const getUserHandler = async () => {
    const userId = localStorage.getItem('userId');

    if (userId) {
      if (user && !user.name) {
        dispatch(getOwnerProfileRequest(userId));
      }
    }
  };

  return (
    <div className={classes.container} style={{ background: theme.BACKGROUND }}>
      <div>
        <div className={classes.closeButtonWrapper}>
          <ButtonBase
            className={classes.closeButton}
            style={{ background: theme.BACKGROUND }}
            onClick={() => dispatch(closeMenu())}
          >
            <CloseIcon />
          </ButtonBase>
        </div>
        <DrawerProfile user={user || null} userIsLoading={userIsLoading} />
        <DrawerNav user={user} kreiseAmount={user.kreise?.length || 0} handleLogOut={handleLogOut} />
      </div>
      <DrawerFooter handleLogOut={handleLogOut} />
    </div>
  );
};

export default Drawer;
